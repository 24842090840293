import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FileUploader } from "react-drag-drop-files";
import { addNewWatch } from "../../../store/addWatchSlice";

import { fetchClientsName } from "../../../store/clientsNameSlice";
import uploadIcon from "../../../assets/images/upload-icon.svg";
import addIcon from "../../../assets/images/Add_round.svg";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";

import Select from "react-select";

import "./add.css";

function Add(props) {
  // const INITIAL_STATE = {
  //   image: "",
  //   watch_name: "",
  //   brand_name: "",
  //   owner_firstname: "",
  //   owner_lastname: "",
  //   price: "",
  //   email: "",
  //   attributes: {
  //     case_finishes: "",
  //     case_height: "",
  //     case_strap: "",
  //     case_screwed_in_lugs: "",
  //     case_diameter: "",
  //     case_water_proofing: "",
  //     case_clasp: "",
  //     case_back: "",
  //     dial_glass: "",
  //     dial_center_dial: "",
  //     dial_exterior_elevation: "",
  //     dial_complication: "",
  //     dial_hands: "",
  //     crown_info: "",
  //     crown_material: "",
  //     movement_automatic: "",
  //     movement_access: "",
  //     guarantee_statement: "",
  //     guarantee_purchase_time: "",
  //     guarantee_purchase_date: "",
  //   },
  // };

  const INITIAL_STATE = {
    image: "",
    watch_name: "",
    watchId: "",
    brand_name: "",
    owner_firstname: "",
    owner_lastname: "",
    clientEmail: "",
    variantTitle: "",
    variantID: "",

    price: "",
    email: "",
    attributes: {
      Age_Group: "",
      Average_Rating: "",
      Bracelet: "",
      Color: "",
      Condition: "",
      Dial_Size: "",
      Gender: "",
      Glass: "",
      Made_in: "",
      Movement: "",
      Title: "",
      Water_resistance: "",
    },
  };

  const REQUIRED_FIELDS = [
    "image",
    "watch_name",
    "watchId",
    "brand_name",
    "owner_firstname",
    "owner_lastname",
    "clientEmail",
    "price",
    "attributes.Age_Group",
    "attributes.Average_Rating",
    "attributes.Bracelet",
    "attributes.Color",
    "attributes.Condition",
    "attributes.Dial_Size",
    "attributes.Gender",
    "attributes.Glass",
    "attributes.Made_in",
    "attributes.Movement",
    "attributes.Title",
    "attributes.Water_resistance",
  ];

  const REQUIRED_FIELDS_CLIENT = [
    "image",
    "watch_name",
    "watchId",
    "brand_name",
    "owner_firstname",
    "owner_lastname",
    "clientEmail",
    "price",
    "attributes.Age_Group",
    "attributes.Average_Rating",
    "attributes.Bracelet",
    "attributes.Color",
    "attributes.Condition",
    "attributes.Dial_Size",
    "attributes.Gender",
    "attributes.Glass",
    "attributes.Made_in",
    "attributes.Movement",
    "attributes.Title",
    "attributes.Water_resistance",
  ];

  const validateField = (fieldName, value) => {
    const pattern = /^[a-zA-Z].*/;
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    switch (fieldName) {
      case "image":
        if (!value) return "Image is required.";
        if (value.size > 2 * 1024 * 1024)
          return "File size must be less than or equal to 2MB.";
        break;

      case "owner_firstname":
        if (!value)
            // return `${fieldName.split(".").pop().replace("_", " ")} is required.`;
          return `Owner First Name is required.`;

        if (!pattern.test(value)) return "Input is not valid.";
        break;
      case "owner_lastname":
        if (!value)
            // return `${fieldName.split(".").pop().replace("_", " ")} is required.`;
          return `Owner Last Name is required.`;

        if (!pattern.test(value)) return "Input is not valid.";
        break;
      case "attributes.Bracelet":
      case "attributes.Condition":
      case "attributes.Gender":
      case "attributes.Movement":
      case "attributes.Water_resistance":
      case "attributes.Made_in":
        if (!value)
          // return `${fieldName.split(".").pop().replace("_", " ")} is required.`;
          return `${fieldName
            .split(".")
            .pop()
            .replace("_", " ")
            .replace(/^\w/, (c) => c.toUpperCase())} is required.`;

        if (!pattern.test(value)) return "Input is not valid.";
        break;
      case "watch_name":
        if (!value)
            // return `${fieldName.split(".").pop().replace("_", " ")} is required.`;
          return `Watch name is required.`;

        if (!pattern.test(value)) return "Input is not valid.";
        break;
      case "watchId":
        if (!value)
            // return `${fieldName.split(".").pop().replace("_", " ")} is required.`;
          return `Watch ID is required.`;

        if (!pattern.test(value)) return "Input is not valid.";
        break;
      case "attributes.Average_Rating":
      case "attributes.Dial_Size":
      case "attributes.Title":
      case "attributes.Color":
      case "attributes.Age_Group":
      case "attributes.Glass":
        if (!value)
          // return `${fieldName.split(".").pop().replace("_", " ")} is required.`;
          return `${fieldName
            .split(".")
            .pop()
            .replace("_", " ")
            .replace(/^\w/, (c) => c.toUpperCase())} is required.`;

        break;

      case "price":
        if (!value)
            // return `${fieldName.split(".").pop().replace("_", " ")} is required.`;
          return `Price is required.`;

        if (!pattern.test(value)) return "Input is not valid.";
        break;
      case "attributes.case_height":
      case "attributes.case_water_proofing":
      case "attributes.case_diameter":
        if (!value)
          // return `${fieldName.split(".").pop().replace("_", " ")} is required.`;
          return `${fieldName
            .split(".")
            .pop()
            .replace("_", " ")
            .replace(/^\w/, (c) => c.toUpperCase())} is required.`;

        if (value < 0) return "Please enter a valid number.";
        break;
      case "email":
      case "clientEmail":
      case "merchantEmail":
        if (!value) return "Email is required.";
        if (!emailPattern.test(value)) return "Invalid email format.";
        break;
      case "attributes.guarantee_purchase_time":
      case "attributes.guarantee_purchase_date":
        if (!value)
          // return `${fieldName.split(".").pop().replace("_", " ")} is required.`;
          return `${fieldName
            .split(".")
            .pop()
            .replace("_", " ")
            .replace(/^\w/, (c) => c.toUpperCase())} is required.`;
        break;
      default:
        return "";
    }
    return "";
  };

  const [addClient, setAddClient] = useState(false);
  const [fields, setFields] = useState(INITIAL_STATE);
  const [errors, setErrors] = useState({});
  const token = localStorage.getItem("token");
  const fileTypes = ["JPG", "PNG", "GIF"];
  const [imageURL, setImageURL] = useState("");
  const [addNewClient, setAddNewClient] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSelect = (value, label) => {
    let text = value.label.split(" ");
    let email = value.email;
    setFields((prevState) => ({
      ...prevState,
      ["owner_firstname"]: text[0],
    }));
    setFields((prevState) => ({
      ...prevState,
      ["owner_lastname"]: text[1],
    }));
    setFields((prevState) => ({
      ...prevState,
      ["clientEmail"]: email,
    }));
    setSelectedOption(value);
  };

  const dispatch = useDispatch();
  const handleClose = () => {
    setAddClient(false);
    props.setShowModal(false);
    setFields(INITIAL_STATE);
    setErrors({});
    setImageURL("");
  };

  const { data: clientsNameData, status } = useSelector(
    (state) => state.clientsName
  );

  useEffect(() => {
    dispatch(fetchClientsName(token));
  }, [props.setAddButton]);

  const options = clientsNameData.clients?.map((client) => ({
    value: client._id,
    email: client.email,
    label: `${client.clientFirstName} ${client.clientLastName}`,
  }));

  const handleInputChange = (event) => {
    if (event.type === "change") {
      const { name, value } = event.target;
      // Validate input before updating state
      const errorMessage = validateField(name, value);
      setErrors({
        ...errors,
        [name]: errorMessage,
      });
      const isNested = name.includes(".");
      if (isNested) {
        // If the property is nested, split the name and create a new object with the updated state
        const [parentKey, childKey] = name.split(".");
        setFields((prevState) => ({
          ...prevState,
          [parentKey]: {
            ...prevState[parentKey],
            [childKey]: value,
          },
        }));
      } else {
        // If the property is not nested, update the state directly
        setFields((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    } else {
      setImageURL(URL.createObjectURL(event));
      const errorMessage = validateField("image", event);
      setErrors({
        ...errors,
        ["image"]: errorMessage,
      });
      setFields({ ...fields, ["image"]: event });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const userData = localStorage.getItem("userData");

    // Parse user data
    const parsedUserData = JSON.parse(userData);
    // Validate required fields
    const newErrors = {};
    if (addClient) {
      REQUIRED_FIELDS_CLIENT.forEach((fieldName) => {
        const isNested = fieldName.includes(".");
        if (!isNested) {
          const errorMessage = validateField(fieldName, fields[fieldName]);
          if (errorMessage) newErrors[fieldName] = errorMessage;
        } else {
          const [parentKey, childKey] = fieldName.split(".");
          const errorMessage = validateField(
            fieldName,
            fields.attributes[childKey]
          );
          if (errorMessage) newErrors[fieldName] = errorMessage;
        }
      });
    } else {
      REQUIRED_FIELDS.forEach((fieldName) => {
        const isNested = fieldName.includes(".");
        if (!isNested) {
          const errorMessage = validateField(fieldName, fields[fieldName]);
          if (errorMessage) newErrors[fieldName] = errorMessage;
        } else {
          const [parentKey, childKey] = fieldName.split(".");
          const errorMessage = validateField(
            fieldName,
            fields.attributes[childKey]
          );
          if (errorMessage) newErrors[fieldName] = errorMessage;
        }
      });
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setLoading(true);

      let formData = new FormData();
      formData.append("image", fields.image);
      formData.append("watchName", fields.watch_name);
      formData.append("watchId", fields.watchId);
      formData.append("clientFirstName", fields.owner_firstname);
      formData.append("clientLastName", fields.owner_lastname);
      formData.append("price", fields.price);
      formData.append("clientEmail", fields.clientEmail);
      formData.append("merchantEmail", parsedUserData.email);
      formData.append("variantTitle", fields.variantTitle);
      formData.append("variantId", fields.variantID);

      // Convert attributes object to array of key-value pairs
      const attributesArray = Object.keys(fields.attributes).map((key) => ({
        [key.replace(/_/g, " ")]: fields.attributes[key],
      }));

      formData.append("attributes", JSON.stringify(attributesArray));

      dispatch(addNewWatch({ formData, token }))
        .unwrap()
        .then((res) => {
          if (res.success) {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Watch added successfully.",
              confirmButtonColor: "#4182EB",
            });
            setLoading(false);
            props.setAddButton(true);
            handleClose();
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Watch not added.",
              confirmButtonColor: "#4182EB",
            });
            setLoading(false);
            props.setAddButton(true);
            handleClose();
          }
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Watch not added.Something went wrong!!!",
            confirmButtonColor: "#4182EB",
          });
          setLoading(false);
          props.setAddButton(true);
          handleClose();
        });
    }
  };
  return (
    <>
      <Modal show={props.showModal} onHide={handleClose} centered>
        <Modal.Body>
          {/* <div className="modal-header"></div> */}
          <form onSubmit={handleSubmit}>
            <div className="d-flex justify-content-between pb-3">
              <div className="add-watch-form-heading">Add a Watch</div>
              <button
                type="button"
                className="btn-close p-1"
                onClick={handleClose}
                style={{
                  backgroundColor: "#E2E2E2",
                  borderRadius: "200px",
                }}
              ></button>
            </div>
            <div className="d-flex justify-content-center align-items-center fileDrag mb-1">
              <FileUploader
                type="file"
                name="image"
                handleChange={handleInputChange}
                types={fileTypes}
                children={
                  <div>
                    {imageURL ? (
                      <div className="d-flex flex-column align-items-center pt-3 pb-3">
                        <img
                          className="w-50 pb-2"
                          src={imageURL}
                          alt="img"
                          style={{ maxHeight: "200px" }}
                        />
                      </div>
                    ) : (
                      <div className="d-flex flex-column align-items-center pt-3 pb-3">
                        <img className="w-25 pb-2" src={uploadIcon} alt="img" />
                        <div className="drag-box-text pb-1">
                          Upload an image or drag and drop
                        </div>
                        <div className="drag-box-text2">
                          PNG, JPG, GIF up to 2MB
                        </div>
                      </div>
                    )}
                  </div>
                }
              />
            </div>
            {errors.image && (
              <span className="error-field d-flex justify-content-center mb-2">
                {errors.image}
              </span>
            )}
            <div className="d-flex flex-row">
              <div className="d-flex flex-column col-sm-5 col-6 me-sm-5 pb-2">
                <span className="add-watch-form-text">Watch Name *</span>
                <input
                  type="text"
                  name="watch_name"
                  value={fields.watch_name}
                  onChange={handleInputChange}
                  className="input-box mb-2 p-1"
                />
                {errors.watch_name && (
                  <span className="error-field">{errors.watch_name}</span>
                )}
              </div>
              <div className="d-flex flex-column col-sm-5 col-6 ms-sm-4">
                <span className="add-watch-form-text">Watch Id *</span>
                <input
                  type="text"
                  name="watchId"
                  value={fields.watchId}
                  onChange={handleInputChange}
                  className="input-box mb-2 p-1"
                />
                {errors.watchId && (
                  <span className="error-field">{errors.watchId}</span>
                )}
              </div>
            </div>
            <div className="d-flex flex-row">
              <div className="d-flex flex-column col-sm-5 col-6 me-sm-5 pb-2">
                <span className="add-watch-form-text">Variant Title</span>
                <input
                  type="text"
                  name="variantTitle"
                  value={fields.variantTitle}
                  onChange={handleInputChange}
                  className="input-box mb-2 p-1"
                />
                {errors.variantTitle && (
                  <span className="error-field">{errors.variantTitle}</span>
                )}
              </div>
              <div className="d-flex flex-column col-sm-5 col-6 ms-sm-4">
                <span className="add-watch-form-text">Variant Id</span>
                <input
                  type="text"
                  name="variantID"
                  value={fields.variantID}
                  onChange={handleInputChange}
                  className="input-box mb-2 p-1"
                />
                {errors.variantID && (
                  <span className="error-field">{errors.variantID}</span>
                )}
              </div>
            </div>
            <div className="d-flex flex-column pb-2">
              <span className="add-watch-form-text">Price *</span>
              <input
                type="number"
                step="any"
                min="0"
                name="price"
                value={fields.price}
                onChange={handleInputChange}
                className="input-box mb-2 p-1"
              />
              {errors.price && (
                <span className="error-field">{errors.price}</span>
              )}
            </div>

            {addClient ? (
              <div className="d-flex align-items-center mb-2">
                <div className="add-watch-form-heading2">CLIENT </div>
                <div
                  className="w-100 ms-3"
                  style={{
                    border: "1px solid #E2E2E2",
                    borderRadius: "120px",
                  }}
                ></div>
                <div
                  className="ms-3 mb-2"
                  onClick={() => {
                    setAddClient(false);
                    setFields((prevState) => ({
                      ...prevState,
                      ["owner_firstname"]: "",
                      ["owner_lastname"]: "",
                      ["clientEmail"]: "",
                    }));
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    data-toggle="tooltip"
                    title="Close tab"
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/28/White_X_in_red_background.svg/2048px-White_X_in_red_background.svg.png"
                    alt="icon"
                    className=""
                    style={{ width: "15px" }}
                  />
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-center pb-2">
                <button
                  onClick={() => {
                    setAddClient(true);
                    setFields((prevState) => ({
                      ...prevState,
                      ["owner_firstname"]: "",
                      ["owner_lastname"]: "",
                      ["clientEmail"]: "",
                    }));
                  }}
                  className="add-watch-btn pt-1 pb-1 ps-2 pe-2"
                >
                  ADD A CLIENT <img src={addIcon} alt="icon" className="ps-1" />
                </button>
              </div>
            )}

            {clientsNameData.clients?.length > 0 && !addClient && (
              <div className="d-flex">
                <div
                  className="d-flex flex-column pb-2"
                  style={{ width: "595px" }}
                >
                  <label className="add-watch-form-text">Select Client</label>
                  <div>
                    <Select
                      className="add-watch-form-text mb-2"
                      options={options}
                      onChange={handleSelect}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="d-flex flex-row">
              <div className="d-flex flex-column col-sm-5 col-6 me-sm-5 pb-2">
                <span className="add-watch-form-text">First Name *</span>
                <input
                  type="text"
                  name="owner_firstname"
                  value={fields.owner_firstname}
                  onChange={handleInputChange}
                  className="input-box mb-2 p-1"
                  readOnly={clientsNameData.clients?.length != 0 && !addClient}
                />
                {errors.owner_firstname && (
                  <span className="error-field">{errors.owner_firstname}</span>
                )}
              </div>
              <div className="d-flex flex-column col-sm-5 col-6 ms-sm-4">
                <span className="add-watch-form-text">Last Name *</span>
                <input
                  type="text"
                  name="owner_lastname"
                  value={fields.owner_lastname}
                  onChange={handleInputChange}
                  className="input-box mb-2 p-1"
                  readOnly={clientsNameData.clients?.length != 0 && !addClient}
                />
                {errors.owner_lastname && (
                  <span className="error-field">{errors.owner_lastname}</span>
                )}
              </div>
            </div>
            <div className="d-flex flex-column pb-2">
              <span className="add-watch-form-text">Client Email *</span>
              <input
                type="text"
                name="clientEmail"
                value={fields.clientEmail}
                onChange={handleInputChange}
                className="input-box mb-2 p-1"
                readOnly={clientsNameData.clients?.length != 0 && !addClient}
              />
              {errors.clientEmail && (
                <span className="error-field">{errors.clientEmail}</span>
              )}
            </div>

            {/* 
            {addClient ? (
              <>
                <div className="d-flex align-items-center mb-2">
                  <div className="add-watch-form-heading2">CLIENT</div>
                  <div
                    className="w-100 ms-3"
                    style={{
                      border: "1px solid #E2E2E2",
                      borderRadius: "120px",
                    }}
                  ></div>
                  {addClient ? (
                    <div
                      className="ms-3 mb-2"
                      onClick={() => {
                        setAddClient(false);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        data-toggle="tooltip"
                        title="Close tab"
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/28/White_X_in_red_background.svg/2048px-White_X_in_red_background.svg.png"
                        alt="icon"
                        className=""
                        style={{ width: "15px" }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {clientsNameData.clients?.length > 0 && !addNewClient ? (
                  <div className="d-flex">
                    <div
                      className="d-flex flex-column"
                      style={{ width: "595px" }}
                    >
                      <label className="add-watch-form-text">
                        Select your name
                      </label>
                      <div>
                        <Select
                          className="add-watch-form-text mb-2"
                          options={options}
                          onChange={handleSelect}
                        />
                      </div>
                    </div>
                    <div
                      className="mt-4 ms-1"
                      onClick={() => {
                        setAddNewClient(true);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        data-toggle="tooltip"
                        title="Add New Client"
                        src={transferIcon}
                        alt="icon"
                        className=""
                        style={{ background: "#4182EB", borderRadius: "50%" }}
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="d-flex">
                      <div className="d-flex flex-column col-sm-5 col-6 me-sm-5">
                        <span className="add-watch-form-text">
                          Owner First Name
                        </span>
                        <input
                          type="text"
                          name="owner_firstname"
                          value={fields.owner_firstname}
                          onChange={handleInputChange}
                          className="input-box mb-2 p-1"
                        />
                        {errors.owner_firstname && (
                          <span className="error-field">
                            {errors.owner_firstname}
                          </span>
                        )}
                      </div>

                      <div className="d-flex flex-column col-sm-5 col-6 ms-sm-4 me-sm-1">
                        <span className="add-watch-form-text">
                          Owner Last Name
                        </span>
                        <input
                          type="text"
                          name="owner_lastname"
                          value={fields.owner_lastname}
                          onChange={handleInputChange}
                          className="input-box mb-2 p-1"
                        />
                        {errors.owner_lastname && (
                          <span className="error-field">
                            {errors.owner_lastname}
                          </span>
                        )}
                      </div>
                      {clientsNameData.clients?.length > 0 ? (
                        <div
                          onClick={() => {
                            setAddNewClient(false);
                          }}
                          style={{ cursor: "pointer", marginTop: "18px" }}
                        >
                          <img
                            data-toggle="tooltip"
                            title="Choose Old Client"
                            src={transferIcon}
                            alt="icon"
                            className=""
                            style={{
                              background: "#4182EB",
                              borderRadius: "50%",
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="d-flex flex-column">
                      <span className="add-watch-form-text">Email</span>
                      <input
                        type="text"
                        name="email"
                        value={fields.email}
                        onChange={handleInputChange}
                        className="input-box mb-2 p-1"
                      />
                      {errors.email && (
                        <span className="error-field">{errors.email}</span>
                      )}
                    </div>
                  </>
                )}
              </>
            ) : (
              <div className="d-flex justify-content-center pb-2">
                <button
                  onClick={() => {
                    setAddClient(true);
                  }}
                  className="add-watch-btn pt-1 pb-1 ps-2 pe-2"
                >
                  ADD A CLIENT <img src={addIcon} alt="icon" className="ps-1" />
                </button>
              </div>
            )} */}

            <div className="d-flex align-items-center mb-2">
              <div className="add-watch-form-heading2">Attributes</div>
              <div
                className="w-100 ms-3"
                style={{
                  border: "1px solid #E2E2E2",
                  borderRadius: "120px",
                }}
              ></div>
            </div>

            <div className="d-flex flex-row">
              <div className="d-flex flex-column col-sm-5 col-6 me-sm-5 pb-2">
                <span className="add-watch-form-text">Title *</span>
                <input
                  type="text"
                  name="attributes.Title"
                  value={fields.attributes.Title}
                  onChange={handleInputChange}
                  className="input-box mb-2 p-1"
                />
                {errors["attributes.Title"] && (
                  <span className="error-field">
                    {errors["attributes.Title"]}
                  </span>
                )}
              </div>
              <div className="d-flex flex-column col-sm-5 col-6 ms-sm-4">
                <span className="add-watch-form-text">Water Resistance *</span>
                <input
                  type="text"
                  name="attributes.Water_resistance"
                  value={fields.attributes.Water_resistance}
                  onChange={handleInputChange}
                  className="input-box mb-2 p-1"
                />
                {errors["attributes.Water_resistance"] && (
                  <span className="error-field">
                    {errors["attributes.Water_resistance"]}
                  </span>
                )}
              </div>
            </div>

            <div className="d-flex flex-row">
              <div className="d-flex flex-column col-sm-5 col-6 me-sm-5 pb-2">
                <span className="add-watch-form-text">Age Group *</span>
                <input
                  type="text"
                  name="attributes.Age_Group"
                  value={fields.attributes.Age_Group}
                  onChange={handleInputChange}
                  className="input-box mb-2 p-1"
                />
                {errors["attributes.Age_Group"] && (
                  <span className="error-field">
                    {errors["attributes.Age_Group"]}
                  </span>
                )}
              </div>
              <div className="d-flex flex-column col-sm-5 col-6 ms-sm-4">
                <span className="add-watch-form-text">Glass *</span>
                <input
                  type="text"
                  name="attributes.Glass"
                  value={fields.attributes.Glass}
                  onChange={handleInputChange}
                  className="input-box mb-2 p-1"
                />
                {errors["attributes.Glass"] && (
                  <span className="error-field">
                    {errors["attributes.Glass"]}
                  </span>
                )}
              </div>
            </div>

            <div className="d-flex flex-row">
              <div className="d-flex flex-column col-sm-5 col-6 me-sm-5 pb-2">
                <span className="add-watch-form-text">Color *</span>
                <input
                  type="text"
                  name="attributes.Color"
                  value={fields.attributes.Color}
                  onChange={handleInputChange}
                  className="input-box mb-2 p-1"
                />
                {errors["attributes.Color"] && (
                  <span className="error-field">
                    {errors["attributes.Color"]}
                  </span>
                )}
              </div>
              <div className="d-flex flex-column col-sm-5 col-6 ms-sm-4">
                <span className="add-watch-form-text">Gender *</span>
                <input
                  type="text"
                  name="attributes.Gender"
                  value={fields.attributes.Gender}
                  onChange={handleInputChange}
                  className="input-box mb-2 p-1"
                />
                {errors["attributes.Gender"] && (
                  <span className="error-field">
                    {errors["attributes.Gender"]}
                  </span>
                )}
              </div>
            </div>

            <div className="d-flex flex-row">
              <div className="d-flex flex-column col-sm-5 col-6 me-sm-5 pb-2">
                <span className="add-watch-form-text">Condition *</span>
                <input
                  type="text"
                  name="attributes.Condition"
                  value={fields.attributes.Condition}
                  onChange={handleInputChange}
                  className="input-box mb-2 p-1"
                />
                {errors["attributes.Condition"] && (
                  <span className="error-field">
                    {errors["attributes.Condition"]}
                  </span>
                )}
              </div>
              <div className="d-flex flex-column col-sm-5 col-6 ms-sm-4">
                <span className="add-watch-form-text">Bracelet *</span>
                <input
                  type="text"
                  name="attributes.Bracelet"
                  value={fields.attributes.Bracelet}
                  onChange={handleInputChange}
                  className="input-box mb-2 p-1"
                />
                {errors["attributes.Bracelet"] && (
                  <span className="error-field">
                    {errors["attributes.Bracelet"]}
                  </span>
                )}
              </div>
            </div>

            <div className="d-flex flex-row">
              <div className="d-flex flex-column col-sm-5 col-6 me-sm-5 pb-2">
                <span className="add-watch-form-text">Made In *</span>
                <input
                  type="text"
                  name="attributes.Made_in"
                  value={fields.attributes.Made_in}
                  onChange={handleInputChange}
                  className="input-box mb-2 p-1"
                />
                {errors["attributes.Made_in"] && (
                  <span className="error-field">
                    {errors["attributes.Made_in"]}
                  </span>
                )}
              </div>
              <div className="d-flex flex-column col-sm-5 col-6 ms-sm-4">
                <span className="add-watch-form-text">Dial Size *</span>
                <input
                  type="text"
                  name="attributes.Dial_Size"
                  value={fields.attributes.Dial_Size}
                  onChange={handleInputChange}
                  className="input-box mb-2 p-1"
                />
                {errors["attributes.Dial_Size"] && (
                  <span className="error-field">
                    {errors["attributes.Dial_Size"]}
                  </span>
                )}
              </div>
            </div>

            <div className="d-flex flex-row">
              <div className="d-flex flex-column col-sm-5 col-6 me-sm-5 pb-2">
                <span className="add-watch-form-text">Movement *</span>
                <input
                  type="text"
                  name="attributes.Movement"
                  value={fields.attributes.Movement}
                  onChange={handleInputChange}
                  className="input-box mb-2 p-1"
                />
                {errors["attributes.Movement"] && (
                  <span className="error-field">
                    {errors["attributes.Movement"]}
                  </span>
                )}
              </div>
              <div className="d-flex flex-column col-sm-5 col-6 ms-sm-4">
                <span className="add-watch-form-text">Average Rating *</span>
                <input
                  type="text"
                  name="attributes.Average_Rating"
                  value={fields.attributes.Average_Rating}
                  onChange={handleInputChange}
                  className="input-box mb-2 p-1"
                />
                {errors["attributes.Average_Rating"] && (
                  <span className="error-field">
                    {errors["attributes.Average_Rating"]}
                  </span>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-center">
              {loading ? (
                <div className="add-watch-btn pt-2 pb-2 ps-4 pe-4">
                  <div
                    className="spinner-border spinner-border-sm text-light"
                    role="status"
                  ></div>
                </div>
              ) : (
                <button
                  type="submit"
                  className="add-watch-btn pt-2 pb-2 ps-4 pe-4"
                >
                  ADD A WATCH <img src={addIcon} alt="icon" className="ps-1" />
                </button>
              )}
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Add;
