  import React, { useState, useEffect } from "react";
  import { useSelector } from "react-redux";
  import Logout from "../Logout/logout";
  import Tabs from "../Tabs/tabs";
  import { STATUSES } from "../../store/watchSlice";
  import axios from "axios";
  import ProgressiveImage from "react-progressive-graceful-image";
  import LazyLoad from "react-lazyload";
  import editIcon from "../../assets/images/edit-icon.svg";
  import dateFormat from "dateformat";
  import searchIcon from "../../assets/images/Search_alt.svg";
  import UpdateMetaFields from "./UpdateMetaFields";
  import WatchDetailForm from "./watchDetailForm";
  import  {baseURLClient } from "../../axios";
  import ReactPaginate from "react-paginate";
  import "./mantainance.css";
  const placeholder = (
    <img
      src={"https://miro.medium.com/v2/resize:fit:512/0*pyRw1qikTI1eqGm9.gif"}
      alt="placeholder"
      style={{ maxHeight: "50px", maxWidth: "60px" }}
    />
  );

  let timeoutId = null;

  export default function UserWatches() {
    // const [checkedAll, setCheckedAll] = useState(false);
    const { theme } = useSelector((store) => store.theme);
    const [mintedWatches, setMintedWatches] = useState(null);
    const [selectedWatch, setSelectedWatch] = useState({});
    const [openWatchForm, setOpenWatchForm] = useState(false);
    const [openDetailConfig, setOpenDetailConfig] = useState(false);
    const [openAsReadOnly, setOpenAsReadOnly] = useState(false);
    const [search, setSearch] = useState("");
    const [status, setStatus] = useState({
      loading: true,
      success: false,
      error: null,
    });
    const [page, setPage] = useState(1); // Current page
    const [pageCount, setPageCount] = useState(1); // Total pages
    const pageSize = 10; // Items per page
    const [allWatches, setAllWatches] = useState([]);

  function handlePageClick({ selected }) {
    setPage(selected + 1);
    setMintedWatches(allWatches.slice(selected * pageSize, (selected + 1) * pageSize));
  } 

    function handleUpdateMetaData(watch, readOnly) {
      setSelectedWatch(watch);
      setOpenDetailConfig(true);
      setOpenAsReadOnly(readOnly);
    }


    function handleWatchDetailForm(watch, readOnly) {
      setSelectedWatch(watch);
      setOpenWatchForm(true);
      setOpenAsReadOnly(readOnly);
    }

    const debouncedHandleChange = (value) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null; // Reset timeoutId after clearing
      }
      timeoutId = setTimeout(() => {
        fetchData(value);
      }, 800); // Adjust the delay as needed
    };


    const fetchData = async (email, page = 1) => {
      setMintedWatches([]);
      try {
        const payload = {
          _owner_email: email === "" ? null : email,
          page: page,
          limit: pageSize, // Add limit parameter to control the number of items per page
        };
        const token = localStorage.getItem("token");
        const response = await axios.post(
          `${baseURLClient}user-watches/get-all-watches`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const filteredData = response.data.watches.filter((item) => item.tokenId); 
        setAllWatches(filteredData);
        
        // slice filteredData to get the number of items per page
        setMintedWatches(filteredData.slice((page - 1) * pageSize, page * pageSize));
        setPageCount(Math.ceil(filteredData.length / pageSize));
        setStatus({ loading: false, success: true, error: null });
        if (response.success === false) {
          setStatus({ loading: false, success: true, error: response.msg });
        }
      } catch (error) {
        setMintedWatches([]);
        console.error(error);
        setStatus({
          loading: false,
          success: true,
          error: error.response.data.msg,
        });
      }
    };
    

    useEffect(() => {
      fetchData(search, page);
    }, [search]);
    
    const RenderItems = (watch) => {
      return watch.map((data) => (
        <tr key={data._id} className={`watches-text`}>
          {/* <td>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" disabled />
            </div>
          </td> */}
          <td>
            <div>{data.watchId}</div>
          </td>
          <td className="col-4">
            <div className="d-flex">
              <div className="d-flex justify-content-center col-2">
                <ProgressiveImage src={`${data.imageURI}`} placeholder="">
                  {(src, loading) => {
                    return loading ? (
                      placeholder
                    ) : (
                      <LazyLoad once>
                        <img
                          src={src}
                          alt="watch"
                          style={{ maxHeight: "50px", maxWidth: "40px" }}
                        />
                      </LazyLoad>
                    );
                  }}
                </ProgressiveImage>
              </div>

              <div
                onClick={() => {
                  handleWatchDetailForm(data,true)
                  // handleUpdateMetaData(data, true);
                }}
                style={{ cursor: "pointer" }}
                className="pe-3 ps-2 col-10"
              >
                {data.name}
              </div>
            </div>
          </td>
          <td>
            <div>{dateFormat(data.createdAt, "mmm dS, yyyy")}</div>
          </td>
          <td>
            <div>{data.email}</div>
          </td>
          <td>
            <div className="d-flex">
              {data.isCertified === true ? (
                <div 
                className={`watch-status ps-2 pe-2 `}>Certified</div>
              ) : (
                ""
              )}
              {data.isCertified === false ? (
                <div className={`watch-status2 ps-2 pe-2`}>Not Certified</div>
              ) : (
                ""
              )}
            </div>
          </td>
          <td>
            {/* <div>
          <button
    style={{
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "11px",
      color: "#ffffff",
      background: "#78b5f3",
      borderRadius: "10px",
      borderColor:"#ffffff"
    }}

      onClick={() => {
      
        handleUpdateMetaData(data, false);
      }}>
        update
      </button>
      </div> */}

            <img
              onClick={() => {
                handleUpdateMetaData(data, false);
              }}
              style={{ cursor: "pointer" }}
              src={editIcon}
              alt="icon"
              className="p-1 me-2"
            />
          </td>
        </tr>
      ));
    };


    
    return (
      <>
        <div className="d-flex flex-row">
          <Tabs />
          <div className={`w-100`}>
            <div
              className="d-flex justify-content-between pt-3 pb-2"
              style={{
                background: "#FFFFFF",
                border: "1px solid #E2E2E2",
                borderRadius: "5px",
              }}
            >
              <div className="d-flex justify-content-end align-items-center ps-4">
                <div className={`main-heading me-sm-4 me-1`}>Maintenance</div>
              </div>
              <Logout />
            </div>

            <div className={`container-fluid bg-${theme} p-2 main-layout`}>
              <div className="d-flex justify-content-end  align-items-center pb-2">
                <div className="d-flex align-items-center watch-search-bar pe-2 me-2">
                  <input
                    type="text"
                    className="col-9"
                    onChange={(event) => {
                      setSearch(event.target.value);
                      debouncedHandleChange(event.target.value);
                    }}
                    style={{ background: "none", border: "none" }}
                    placeholder={`Email Address... `}
                  />
                  <img
                    className="input-group-text"
                    src={searchIcon}
                    alt=""
                    style={{ background: "none", border: "none" }}
                  />
                </div>
              </div>

              <div
                style={{
                  background: "#FFFFFF",
                  border: "1px solid #E2E2E2",
                  borderRadius: "5px",
                  marginTop: "2px",
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
              >
                <div className="table-responsive main-layout3">
                  <table className="table">
                    <thead className={`watches-heading`}>
                      <tr>
                        {/* <th>
                          <div className="form-check">
                            {checkedAll ? (
                              <input
                                className="form-check-input mt-2"
                                type="checkbox"
                                checked
                              />
                            ) : (
                              <input
                                className="form-check-input mt-2"
                                type="checkbox"
                              />
                            )}
                          </div>
                        </th> */}
                        <th>
                          <div>SN</div>
                        </th>
                        <th>
                          <div>WATCH</div>
                        </th>
                        <th>
                          <div>ADDED</div>
                        </th>
                        <th>
                          <div>OWNER</div>
                        </th>
                        <th>
                          <div>STATUS</div>
                        </th>
                        <th>
                          <div>ACTION</div>
                        </th>
                      </tr>
                    </thead>

                    {mintedWatches && mintedWatches.length > 0 && (
                      <tbody>{RenderItems(mintedWatches)}</tbody>
                    )}
                  </table>
                </div>
                {status === STATUSES.LOADING ? (
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border text-dark" role="status">
                      {/* <span className="visually-hidden">Loading...</span> */}
                    </div>
                  </div>
                ) : (
                  mintedWatches &&
                  allWatches.length < 1 && (
                    <div className="d-flex justify-content-center">
                      <div
                        className="main-heading  pt-3"
                        style={{ width: "max-content" }}
                      >
                        No Data Found
                      </div>
                    </div>
                  )
                )}
                <div className="d-flex justify-content-between align-items-center ps-2 pe-2">
                  {mintedWatches?.length > 0 ? (
                    <div className={`watches-heading`}>
                      Showing {(page - 1) * 10 + 1} to{" "}
                      {mintedWatches?.length + (page - 1) * 10} of{" "}
                      {allWatches.length} entries
                    </div>
                  ) : (
                    <div className={`watches-heading`}>
                      Showing 0 to 0 of 0 entries
                    </div>
                  )}{" "}
                  {mintedWatches?.length > 0 && (
                    <ReactPaginate
                      previousLabel={null}
                      nextLabel={">"}
                      onPageChange={handlePageClick}
                      //renderOnZeroPageCount={null}
                      //pageRangeDisplayed={2}
                      // marginPagesDisplayed={2}
                      pageCount={pageCount}
                      forcePage={page - 1}
                      containerClassName={"pagination p-1"}
                      pageClassName={`watches-heading m-1 d-flex justify-content-center align-items-center`}
                      nextLinkClassName={`watches-heading text-decoration-none m-1`}
                      nextClassName={""}
                      disabledClassName={`watches-heading d-flex justify-content-center align-items-center`}
                      activeClassName={`page-active`}
                    />
                  )}
                </div>

                <div className="d-flex justify-content-center ">
                  {status.loading ? (
                    <div className="spinner-border text-dark" role="status">
                      {/* <span className="visually-hidden">Loading...</span> */}
                    </div>
                  ) : status.error != null || status.error !== "" ? (
                    <div
                      className="main-heading"
                      style={{ width: "max-content" }}
                    >
                      {status.error}
                    </div>
                  ) : (
                    // : search == "" ? (
                    //   <div
                    //     className="main-heading pt-3 pb-3"
                    //     style={{ width: "max-content" }}
                    //   >
                    //     Please provide an email address.
                    //   </div>
                    // )
                    mintedWatches &&
                    mintedWatches.length < 1 && (
                      <div
                        className="main-heading pt-3 pb-3"
                        style={{ width: "max-content" }}
                      >
                        No Data Found
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {openWatchForm && (
          <WatchDetailForm
            watch={selectedWatch}
            setMetaModalClose={() => {
              setSelectedWatch(null);
              setOpenDetailConfig(false);
              setOpenWatchForm(false);
              setOpenAsReadOnly(false);
            }}
            readOnly={openAsReadOnly}
          />
        )}

        {openDetailConfig && (
          <UpdateMetaFields
            watch={selectedWatch}
            setMetaModalClose={() => {
              setSelectedWatch(null);
              setOpenDetailConfig(false);
              setOpenWatchForm(false);
              setOpenAsReadOnly(false);
            }}
            readOnly={openAsReadOnly}
          />
        )}
      </>
    );
  }
